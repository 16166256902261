import React, { useState, useRef, useEffect, useCallback, forwardRef } from 'react'
import Button from '@mui/material/Button'
import styles from "./styles.module.css";
import useInterval from "../../../components/viewer/hooks/useInterval"
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faAngleLeft
} from "@fortawesome/free-solid-svg-icons";

import staticImage from "../../../assets/images";

import HotspotControlBar from './HotspotControlBar'
import HotspotDetail from './HotspotDetails'
import ControlBar from '../../../components/viewer/components/ControlBar/index'
import HotspotAddModal from './HotspotAddModal'
import { useSearchParams } from 'react-router-dom';


// import MediaStreamWithDnD from

const Viewer = forwardRef((
    {
        carlistingUrlsArray,
        carlistingGyroData,
        autoplaySpeed,
        hotspotPins,
        setHotspotPins,
        count,
        setOldCoOrdinates,
        oldCoOrdinates,
        showHotspotControlBar,
        showImages: showCarousel,
        inverse = true,
        dragInterval,
        draggable,
        touchDragInterval,
        touch,
        mousewheel,
        autoplay,
        resumeAutoplayOnMouseLeave,
        mouseleaveDetect,
        stopAutoplayOnMouseEnter,
        stopAutoplayOnClick,
        onNext,
        location,
    }, ref
) => {
    // const { t } = useTranslation()

    const [moveBuffer, setMoveBuffer] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [zoom, setZoom] = useState(1);
    const [sliderSelected, setSliderSelected] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [angle, setAngle] = useState(0);
    const [showImages, setShowImages] = useState(false);
    const [isAddingHotspot, setIsAddingHotspot] = useState(false);
    const [isEditingHotspot, setIsEditingHotspot] = useState(false);
    const [clickedPointCoordinate, setClickedPointCoordinate] = useState(null);
    const [isPinClicked, setIsPinClicked] = useState(false);
    const [isCaptureClicked, setIsCaptureClicked] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [clickedPinUid, setClickedPinUid] = useState("");
    const [changedCoordinates, setChangedCoordinates] = useState(undefined);
    const [hotspotDraggable, setHotspotDraggable] = useState(false);
    const [hotspotNodeHeight, setHotspotNodeHeight] = useState(null);

    console.log("HOTSPOT PINS", hotspotPins, currentImageIndex);

    const background_color = 'ffbf00';

    const [searchParams] = useSearchParams()
    const generateViewerClassName = () => {
        let classNameStr = styles["Viewer-viewer"];
        if (draggable) classNameStr += " " + styles["Viewer-draggable-true"];
        if (touch) classNameStr += " " + styles["Viewer-touch-true"];
        if (mousewheel) classNameStr += " " + styles["Viewer-mousewheel-true"];
        return classNameStr;
    };
    let initial_hotspot_pos_X = -1, initial_hotspot_pos_Y = -1;
    const _viewerImageRef = useRef(null);
    const img_Ref = useRef(null);
    const hotspot_Ref = useRef(null);
    const sliderRef = useRef(null);
    const _draggable = draggable;
    const rangePlus = () => setZoom((z) => (z + 0.1 > 2 ? 2 : Math.round(100 * (z + 0.1)) / 100)); // as 1.1+0.1 = 1.200000000001
    const rangeMinus = () => setZoom((z) => (z - 0.1 < 1 ? 1 : Math.round(100 * (z - 0.1)) / 100));

    const LocationIcon = ({ uid = 'a' }) => {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" key={uid}>
            <path key={uid} fill="#E61A23" d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"></path>
        </svg>
    }

    const getWindowDimensions = useCallback(() => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    });

    const get_coordinates_for_zoom = useCallback((x, y, type) => {
        x = parseInt(x);
        y = parseInt(y);
        console.log("Inside get_coordinates_for_zoom", x, y, type);
        let y_1x, x_1x;
        let img_height = img_Ref?.current?.clientHeight;
        let img_width = img_Ref?.current?.clientWidth;
        if (zoom == 1)
            return { 'x': x, 'y': y };
        if (y > img_height / 2) {
            y = y - img_height / 2;
            y_1x = type === 'increase' ? y * zoom : y / zoom;
            y_1x = y_1x + img_height / 2;
        } else {
            y = img_height / 2 - y;
            y_1x = type === 'increase' ? y * zoom : y / zoom;
            y_1x = img_height / 2 - y_1x;
        }

        if (x > img_width / 2) {
            x = x - img_width / 2;
            x_1x = type === 'increase' ? x * zoom : x / zoom;
            x_1x = x_1x + img_width / 2;
        } else {
            x = img_width / 2 - x;
            x_1x = type === 'increase' ? x * zoom : x / zoom;
            x_1x = img_width / 2 - x_1x;
        }
        console.log("Returned x, y", x_1x, y_1x);

        return { 'x': x_1x, 'y': y_1x }
    }, [zoom]);

    const changeCurrentImage = (imageIndex) => {
        setCurrentImageIndex(imageIndex);
        // refs = null
        setSliderSelected(imageIndex);
        changeAngle(imageIndex);
    };

    const renderImages = () =>
        carlistingUrlsArray.map((src, index) => (
            <img
                key={index}
                src={location + '/' + src}
                ref={currentImageIndex === index ? img_Ref : null}
                onClick={(e) => {
                    if (isAddingHotspot)
                        imageViewerClickHandler(e, "click");
                    else {
                        if (e.target.className.includes('list-image')) {
                            if (clickedPointCoordinate != null)
                                setClickedPointCoordinate(null);
                            setIsPinClicked(false);
                            setClickedPinUid('');
                            setImgSrc('');
                            if (isAutoPlayRunning)
                                toggleAutoplay(false);
                        }
                    }
                }}
                className={`list-image ${styles["Viewer-viewer-image"]} ${currentImageIndex === index
                    ? styles["Viewer-viewer-image-shown"]
                    : styles["Viewer-viewer-image-hidden"]
                    }`}
                alt=""
            />
        ));


    const renderImageList = () => {
        let degree = -48;
        return carlistingUrlsArray.map((src, index) => {
            if (index % Math.ceil(carlistingUrlsArray.length / 7.5) == 0) {
                degree += 48;
                return (
                    <div
                        key={index}
                        className={`single-image-container ${sliderSelected === index ? "selected-images" : ""
                            }`}
                    >
                        <img
                            key={index}
                            src={location + '/' + src}
                            alt=""
                            className={`scroll-images`}
                            onClick={() => changeCurrentImage(index)}
                        />
                        <div className="list-image-title">Exterior {degree}° </div>
                    </div>
                );
            }
        });
    };

    const changeAngle = useCallback(
        (index) => {
            let angle = (360 / carlistingUrlsArray.length) * index;
            setAngle(angle);
        },
        // [_images, setAngle]
        [setAngle]
    );

    const prevFrame = useCallback(() => {
        const newIndex =
            currentImageIndex >= count - 1 ? 0 : currentImageIndex + 1;
        console.log("NEWINDEX", newIndex);
        setCurrentImageIndex(newIndex);
        changeAngle(newIndex);
    }, [count, currentImageIndex, changeAngle]);

    const nextFrame = useCallback(() => {
        const newIndex =
            currentImageIndex <= 0 ? count - 1 : currentImageIndex - 1;
        console.log("NEWINDEX", newIndex);
        setCurrentImageIndex(newIndex);
        changeAngle(newIndex);
    }, [count, currentImageIndex, changeAngle]);

    const nextMove = useCallback(() => {
        return inverse ? prevFrame() : nextFrame();
    }, [inverse, nextFrame, prevFrame]);

    const prevMove = useCallback(() => {
        return inverse ? nextFrame() : prevFrame();
    }, [inverse, nextFrame, prevFrame]);

    const rotateViewerImage = useCallback(
        (e) => {
            const interval = e.touches ? touchDragInterval : dragInterval;
            const eventX = e.touches ? Math.round(e.touches[0].clientX) : e.clientX;
            const coord = eventX - _viewerImageRef?.current?.offsetLeft;
            let newMoveBufffer = moveBuffer;
            if (moveBuffer.length < 2) {
                newMoveBufffer = moveBuffer.concat(coord);
            } else {
                newMoveBufffer = [moveBuffer[1], coord];
            }
            setMoveBuffer(newMoveBufffer);
            const threshold = !(coord % interval);
            const oldMove = newMoveBufffer[0];
            const newMove = newMoveBufffer[1];
            if (threshold && newMove < oldMove) {
                nextMove();
            } else if (threshold && newMove > oldMove) {
                prevMove();
            }
        },
        [dragInterval, moveBuffer, nextMove, prevMove, touchDragInterval]
    );

    const resetMoveBuffer = () => setMoveBuffer([]);

    const toggleAutoplay = useCallback(
        (state) => {
            setIsAutoPlayRunning(state);
        },
        []
    );

    const startDragging = useCallback(() => {
        setIsDragging(true);
    }, []);

    const stopDragging = useCallback(() => {
        setIsDragging(false);
    }, []);


    // handlers
    // Image Touch and Mouse Event handlers
    const imageViewerMouseDownHandler = (e) => {
        if (draggable) {
            if (e.preventDefault) e.preventDefault();
            startDragging();
            rotateViewerImage(e);
        }

        if (isAutoPlayRunning && stopAutoplayOnClick) {
            toggleAutoplay(false);
        }
    };

    const imageViewerMouseUpHandler = (e) => {
        if (_draggable) {
            if (e.preventDefault) e.preventDefault();
            stopDragging();
            resetMoveBuffer();
        }
    };

    const imageViewerMouseMoveHandler = (e) => {
        if (draggable && isDragging) {
            rotateViewerImage(e);
        }
    };

    const imageViewerMouseLeaveHandler = () => {
        if (_draggable) resetMoveBuffer();
        if (!isAutoPlayRunning && resumeAutoplayOnMouseLeave) {
            toggleAutoplay(true);
        }
        if (mouseleaveDetect) {
            stopDragging();
            resetMoveBuffer();
        }
    };

    const imageViewerMouseEnterHandler = () => {
        if (isAutoPlayRunning && stopAutoplayOnMouseEnter) {
            toggleAutoplay(false);
        }
    };

    const imageViewerWheelHandler = useCallback(
        (e) => {
            if (mousewheel) {
                if (e.preventDefault) e.preventDefault();
                e.deltaY / 120 > 0 ? nextMove() : prevMove();
            }
        },
        [mousewheel, nextMove, prevMove]
    );

    const imageViewerTouchStartHandler = useCallback(
        (e) => {
            if (touch) {
                console.log(e);
                if (e.preventDefault) e.preventDefault();
                if (isAddingHotspot) {
                    if (e.target.className.includes('list-image'));
                    imageViewerClickHandler(e, "touch");
                }
                else {
                    if (e.target.className.includes('list-image')) {
                        if (clickedPointCoordinate != null)
                            setClickedPointCoordinate(null);
                        setIsPinClicked(false);
                        setClickedPinUid('');
                        setImgSrc('');
                        if (isAutoPlayRunning)
                            toggleAutoplay(false);
                    }
                    setClickedPointCoordinate(null);
                    startDragging();
                    rotateViewerImage(e);
                }
            }

            if (isAutoPlayRunning && stopAutoplayOnClick) {
                toggleAutoplay(false);
            }
        },
        [
            isAutoPlayRunning,
            rotateViewerImage,
            startDragging,
            stopAutoplayOnClick,
            toggleAutoplay,
            touch,
            isAddingHotspot
        ]
    );

    const imageViewerTouchMoveHandler = useCallback(
        (e) => {
            if (touch) {
                if (e.preventDefault) e.preventDefault();
                rotateViewerImage(e);
            }
        },
        [rotateViewerImage, touch]
    );

    const imageViewerTouchEndHandler = useCallback(
        (e) => {
            if (touch) {
                stopDragging();
                resetMoveBuffer();
            }

            if (!isAutoPlayRunning && resumeAutoplayOnMouseLeave) {
                toggleAutoplay(true);
            }
        },
        [
            isAutoPlayRunning,
            resumeAutoplayOnMouseLeave,
            stopDragging,
            toggleAutoplay,
            touch,
        ]
    );


    // Hotspot Touch Event
    const hotspotTouchStartHandler = useCallback((e) => {
        if (touch) {
            if (e.preventDefault) e.preventDefault();
            console.log("Start handler", e);
        }
    }, [touch])

    const hotspotTouchMoveHandler = useCallback((e, event_type) => {
        if (touch) {
            if (e.preventDefault) e.preventDefault();
            console.log("Touch move", e);
            console.log("MOVE handler", e.target.attributes.uid.value, changedCoordinates);
            let clientX, clientY;
            if (event_type == "mouse") {
                clientX = e.clientX;
                clientY = e.clientY;
            } else {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            }
            let img_top_gap = (window.innerHeight - img_Ref?.current?.clientHeight) / 2;
            let img_left_gap = (window.innerWidth - img_Ref?.current?.clientWidth) / 2;
            if (isEditingHotspot) setChangedCoordinates({ 'uid': e.target.attributes.uid.value, 'x': clientX - img_left_gap, 'y': clientY - img_top_gap });
        }
    }, [touch, changedCoordinates, isEditingHotspot, zoom])

    const hotspotTouchEndHandler = useCallback((e) => {
        if (touch) {
            if (e.preventDefault) e.preventDefault();
            console.log("END handler", e);
            console.log("END handler", e.target.attributes.uid.value)
            if (changedCoordinates == null || changedCoordinates == undefined || changedCoordinates.x == undefined || changedCoordinates.y == undefined) {
                console.log("Inside touch end and inside changesCoordinates", changedCoordinates);
                if (clickedPointCoordinate == null) {
                    setClickedPinUid(e.target.attributes.uid.value);
                    setIsPinClicked(prev => !prev);
                    setImgSrc('');
                    setClickedPointCoordinate(null);
                    return;
                }
            }
            console.log(hotspotPins,"previous")
            setHotspotPins(prev => {
                let { x, y } = get_coordinates_for_zoom(changedCoordinates.x, changedCoordinates.y, 'decrease');
                prev[currentImageIndex] = prev[currentImageIndex].map((pin) => {
                    if (pin.uid === e.target.attributes.uid.value) {
                        console.log(pin.co_ordinates,"hello")
                        pin.co_ordinates.x = x;
                        pin.co_ordinates.y = y;
                    }
                    return pin;
                })
                return prev;
            });
            console.log(hotspotPins,"previous2")
            setChangedCoordinates(undefined);
        }
    }, [touch, changedCoordinates, clickedPointCoordinate, zoom])

    const hotspotMouseDownHandler = (e) => {
        if (e.preventDefault) e.preventDefault();
        if (draggable && isEditingHotspot) {
            initial_hotspot_pos_X = e.clientX;
            initial_hotspot_pos_Y = e.clientY;
            setHotspotDraggable(true)
            console.log("Start Mouse handler", e);
        }
    }

    const hotspotMouseMoveHandler = (e) => {
        if (draggable && hotspotDraggable && isEditingHotspot) {
            if (initial_hotspot_pos_X == e.clientX && initial_hotspot_pos_Y == e.clientY) {
                setChangedCoordinates(undefined)
                return;
            }
            else {
                console.log("Dragging", e);
                let img_top_gap = (window.innerHeight - img_Ref?.current?.clientHeight) / 2;
                let img_left_gap = (window.innerWidth - img_Ref?.current?.clientWidth) / 2;
                setChangedCoordinates({ 'uid': e.target.attributes.uid.value, 'x': e.clientX - img_left_gap + 7, 'y': e.clientY - img_top_gap + 10 });
                return;
            }
        }
    }

    const hotspotMouseUpHandler = (e) => {
        if (draggable && isEditingHotspot && hotspotDraggable && changedCoordinates != undefined) {
            if (e.preventDefault) e.preventDefault();
            if (initial_hotspot_pos_X == e.clientX && initial_hotspot_pos_Y == e.clientY) {
                setHotspotDraggable(false);
                return;
            }
            console.log("MOUSE UP OR END", e);
            setHotspotPins(prev => {
                console.log(prev,"previous")
                let { x, y } = get_coordinates_for_zoom(changedCoordinates.x, changedCoordinates.y, 'decrease');
                prev[currentImageIndex] = prev[currentImageIndex].map((pin) => {
                    if (pin.uid === e.target.attributes.uid.value) {
                        pin.co_ordinates.x = x;
                        pin.co_ordinates.y = y;
                    }
                    return pin;
                })
                return prev;
            });
            setChangedCoordinates(undefined);
        }
        setHotspotDraggable(false);
    }

    const hotspotMouseLeaveHandler = (e) => {
        if (e.preventDefault) e.preventDefault();
        setHotspotDraggable(false);
    }

    const hotspotWheelHandler = useCallback((e) => {
        if (mousewheel) {
            if (e.preventDefault) e.preventDefault();
            console.log("Mouse Wheel", e);
        }
    }, [mousewheel])

    const imageViewerClickHandler = (e, event_type) => {
        console.log(e);
        let clientX, clientY;
        if (event_type == "click") {
            clientX = e.clientX;
            clientY = e.clientY;
        }
        if (event_type == "touch") {
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        }
        // let{x, y} = get_coordinates_for_zoom(clientX, clientY)//here clientX and clientY includes the imageOffset
        let image_height = img_Ref?.current?.clientHeight;
        let image_width = img_Ref?.current?.clientWidth;
        let img_top_gap = (window.innerHeight - image_height) / 2;
        let img_left_gap = (window.innerWidth - image_width) / 2;

        console.log("Inside Image Viewer Click Handler", clientX, clientY);
        setChangedCoordinates(undefined);
        setClickedPinUid('');
        setImgSrc('');
        setIsPinClicked(false);
        setClickedPointCoordinate(() => {
            return { 'index': currentImageIndex, 'left': clientX - img_left_gap, 'top': clientY - img_top_gap }
        });
    };

    useEffect(() => {
        function handleResize() {
            const { width } = getWindowDimensions();
            width < 560 ? setIsMobile(true) : setIsMobile(false);
        }
        handleResize();
        if (window) window.addEventListener("resize", handleResize);
        return () =>
            window ? window.removeEventListener("resize", handleResize) : null;
    }, []);

    useEffect(() => {
        const viewerRef = _viewerImageRef.current;
        viewerRef.addEventListener("touchstart", imageViewerTouchStartHandler, {
            passive: false,
        });
        viewerRef.addEventListener("touchmove", imageViewerTouchMoveHandler, {
            passive: false,
        });
        viewerRef.addEventListener("touchend", imageViewerTouchEndHandler, {
            passive: false,
        });
        viewerRef.addEventListener("wheel", imageViewerWheelHandler, {
            passive: false,
        });

        return () => {
            viewerRef.removeEventListener(
                "touchstart",
                imageViewerTouchStartHandler
            );
            viewerRef.removeEventListener("touchmove", imageViewerTouchMoveHandler);
            viewerRef.removeEventListener("touchend", imageViewerTouchEndHandler);
            viewerRef.removeEventListener("wheel", imageViewerWheelHandler);
        };
    }, [
        imageViewerTouchEndHandler,
        imageViewerTouchMoveHandler,
        imageViewerTouchStartHandler,
        imageViewerWheelHandler,
    ]);

    useEffect(() => {
        toggleAutoplay(true);
        setTimeout(
            function () {
                //Start the timer
                toggleAutoplay(false); //After 1 second, set render to true
            }.bind(this),
            12000
        );
    }, [toggleAutoplay]);

    useEffect(() => {
        if (autoplay) {
            toggleAutoplay(autoplay);
        }
    }, [autoplay, toggleAutoplay]);

    useInterval(
        () => {
            nextMove();
        },
        isAutoPlayRunning ? autoplaySpeed : null
    );

    // To add key press events
    // useEffect(() => {
    //     let timeoutId=null;
    //     const onKeyDown = (e) => {
    //         console.log(e)
    //         if(e.keyCode==37 && e.key=="ArrowLeft")
    //             prevMove();
    //         if(e.keyCode==39 && e.key=="ArrowRight")
    //             nextMove();
    //     }
    //     window.addEventListener('keydown', onKeyDown);
    //     return() => {
    //         window.removeEventListener('keydown', onKeyDown);
    //     }
    // })

    const getModifiedPos = useCallback((current_pos, type) => {
        current_pos = parseInt(current_pos);
        const inner_width = window.innerWidth;
        const inner_height = window.innerHeight;
        if (type === 'left') {
            if (current_pos + 0.35 * inner_width > img_Ref?.current?.clientWidth)
                return current_pos - 0.35 * inner_width - 15;
            return current_pos + 15;
        }
        if (type === "top") {
            if (current_pos + hotspotNodeHeight > img_Ref?.current?.clientHeight)
                return 'auto';
            return current_pos;
        }
        if (type == "bottom") {
            if (current_pos + hotspotNodeHeight > img_Ref?.current?.clientHeight)
                return 0.1 * img_Ref?.current?.clientHeight;
            return 'auto';
        }
        return current_pos;
    }, [imgSrc, hotspotNodeHeight, clickedPointCoordinate, isPinClicked])

    const onRefChange = useCallback(node => {
        if (node && node?.clientHeight != null && node?.clientHeight != undefined && node?.clientHeight != 0)
            setHotspotNodeHeight(node?.clientHeight);
    }, [hotspotNodeHeight, imgSrc, clickedPointCoordinate, isPinClicked])

    const scrollRight = (scrollOffset) => {
        sliderRef.current.scrollLeft += scrollOffset;
    };
    const scrollLeft = (scrollOffset) => {
        sliderRef.current.scrollLeft -= scrollOffset;
    };



    const Adding_Hotspot_Pin = () => {
        let x = clickedPointCoordinate.left; //removed the top and left gap
        let y = clickedPointCoordinate.top;

        return (<div style={{ position: 'absolute', left: `${x - 15}px`, top: `${y - 40}px` }}>
            <div style={{ width: '30px' }}>
                <LocationIcon />
            </div>
        </div>);
    }

    return (
        <>
            <>
                <div style={{ transform: `scale(${zoom})`, transition: 'all 0.15s ease-in-out 0s' }}>
                    <div
                        className={generateViewerClassName()}
                        ref={_viewerImageRef}
                        onMouseDown={imageViewerMouseDownHandler}
                        onMouseUp={imageViewerMouseUpHandler}
                        onMouseMove={imageViewerMouseMoveHandler}
                        onMouseLeave={imageViewerMouseLeaveHandler}
                        onMouseEnter={imageViewerMouseEnterHandler}
                    >
                        {renderImages()}
                    </div>
                </div>
                <>
                    {
                        hotspotPins.length != 0 && hotspotPins[currentImageIndex].length != 0 && hotspotPins[currentImageIndex].map((pin, index) => {
                            let { x, y } = (!changedCoordinates ? get_coordinates_for_zoom(pin.co_ordinates.x, pin.co_ordinates.y, 'increase') : (changedCoordinates.uid !== pin.uid ? get_coordinates_for_zoom(pin.co_ordinates.x, pin.co_ordinates.y, 'increase') : { 'x': changedCoordinates.x, 'y': changedCoordinates.y }));
                            return (
                                <div
                                    key={pin.uid}
                                    uid={pin.uid}
                                    style={{
                                        position: 'absolute',
                                        left: `${x - 7.5}px`, top: `${y - 7.5}px`,
                                        pointerEvents: `${clickedPointCoordinate != null ? 'none' : 'auto'}`,
                                        touchAction: `${clickedPointCoordinate != null ? 'none' : 'auto'}`
                                    }}>
                                    <div
                                        style={{ width: '30px' }}
                                        onTouchStart={hotspotTouchStartHandler}
                                        onTouchMove={hotspotTouchMoveHandler}
                                        onTouchEnd={hotspotTouchEndHandler}
                                        // onWheel={hotspotWheelHandler}
                                        onMouseDown={hotspotMouseDownHandler}
                                        onMouseMove={hotspotMouseMoveHandler}
                                        onMouseUp={hotspotMouseUpHandler}
                                        onMouseLeave={hotspotMouseLeaveHandler}
                                        onClick={() => {
                                            setClickedPinUid(pin.uid)
                                            setIsPinClicked(prev => !prev)
                                            setImgSrc('');
                                        }}
                                        className="location-icon-hotspot-pin Viewer-dots-wrapper"
                                        uid={pin.uid}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" key={pin.uid} uid={pin.uid}>
                                                <path key={pin.uid} uid={pin.uid} fill="#E61A23" d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"></path>
                                            </svg> */}
                                        <div key={pin.uid} uid={pin.uid} className={`${styles["Viewer-dot"]}`}></div>
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </>
                <>
                    {(clickedPointCoordinate != null && clickedPointCoordinate.index == currentImageIndex) && (
                        <Adding_Hotspot_Pin />
                    )}
                </>
                {
                    (clickedPointCoordinate != null && clickedPointCoordinate.index == currentImageIndex) && (
                        <div ref={onRefChange}
                            style={{
                                position: 'absolute',
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%,-50%)"
                            }}>
                            <HotspotDetail
                                setClickedPointCoordinate={setClickedPointCoordinate}
                                setHotspotPins={setHotspotPins}
                                hotspotPins={hotspotPins}
                                setIsAddingHotspot={setIsAddingHotspot}
                                currentImageIndex={currentImageIndex}
                                leftDistance={clickedPointCoordinate.left}
                                topDistance={clickedPointCoordinate.top}
                                setIsCaptureClicked={setIsCaptureClicked}
                                imgSrc={imgSrc}
                                setOldCoOrdinates={setOldCoOrdinates}
                                oldCoOrdinates={oldCoOrdinates}
                                sessionId={searchParams.get("session_id")}
                                setImgSrc={setImgSrc}
                                zoom={zoom}
                                get_coordinates_for_zoom={get_coordinates_for_zoom}
                            />
                        </div>)
                }
                {
                    hotspotPins.length != 0 && hotspotPins[currentImageIndex].length != 0 && isPinClicked && hotspotPins[currentImageIndex].map((pin, index) => {
                        let { x, y } = get_coordinates_for_zoom(pin.co_ordinates.x, pin.co_ordinates.y, 'increase');
                        let left = getModifiedPos(x, 'left');
                        let top = getModifiedPos(y, 'top');
                        let bottom = getModifiedPos(y, 'bottom');
                        return (
                            <div ref={onRefChange}
                                style={{
                                    position: 'absolute',
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%,-50%)"
                                }}>
                                {
                                    pin['uid'] === clickedPinUid && isPinClicked &&
                                    <HotspotDetail
                                        setIsPinClicked={setIsPinClicked}
                                        defaultSubject={pin.subject}
                                        defaultDescription={pin.description}
                                        setHotspotPins={setHotspotPins}
                                        currentImageIndex={currentImageIndex}
                                        leftDistance={x}
                                        topDistance={y}
                                        hotspotPins={hotspotPins}
                                        pinData={pin}
                                        setOldCoOrdinates={setOldCoOrdinates}
                                        oldCoOrdinates={oldCoOrdinates}
                                        sessionId={searchParams.get("session_id")}
                                        setIsCaptureClicked={setIsCaptureClicked}
                                        imgSrc={pin.src}
                                        actualImgSrc={imgSrc}
                                        setImgSrc={setImgSrc}
                                        clickedPinUid={clickedPinUid}
                                        isEditingHotspot={isEditingHotspot}
                                        zoom={zoom}
                                        get_coordinates_for_zoom={get_coordinates_for_zoom}
                                    />
                                }
                            </div>
                        )
                    })
                }
                <>
                    <div
                        className="spin360-radar"
                        style={{ position: 'absolute', bottom: '10px', left: '20px' }}>
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xmlSpace="preserve"
                            viewBox="0 0 256 256"
                            width="50"
                            height="50"
                            style={{ transform: `rotate(${angle}deg)` }}
                        >
                            <g>
                                <circle
                                    strokeOpacity="0.2"
                                    stroke="#FFFFFF"
                                    fillOpacity="0.7"
                                    fill="#000000"
                                    cx="128"
                                    cy="128"
                                    r="128"
                                ></circle>
                                <circle
                                    style={{ stroke: `#${background_color}` }}
                                    cx="128"
                                    cy="128"
                                    r="110"
                                    // stroke="white"
                                    strokeWidth="5"
                                    fill="none"
                                ></circle>
                                <circle cx="128" cy="128" r="10" fill="white"></circle>
                                <polygon
                                    points="0,0 16,16 0,32"
                                    fill="white"
                                    transform="translate(128, 2) rotate(-90 0,16)"
                                ></polygon>
                            </g>
                            <g transform="translate(128, 128) rotate(-135)">
                                <path
                                    style={{ fill: `#${background_color}` }}
                                    d="M0,0 L0,95 A95,95 0 0,0 95,0 Z"
                                    strokeWidth="2"
                                    // stroke="white"
                                    mask="url(#m)"
                                    transform="rotate(360)"
                                ></path>
                            </g>
                        </svg>
                    </div>
                    <span className="spin360-radar-angle" style={{ position: 'absolute', bottom: '30px', left: '30px', color: 'white' }}>
                        {parseInt(Math.round(angle))}&deg;
                    </span>
                </>
                {showHotspotControlBar && searchParams.get("session_id") && (
                <HotspotControlBar
                    onZoomIn={rangePlus}
                    onZoomOut={rangeMinus}
                    zoom={zoom}
                    setZoom={setZoom}
                    isAddingHotspot={isAddingHotspot}
                    setIsAddingHotspot={setIsAddingHotspot}
                    setClickedPointCoordinate={setClickedPointCoordinate}
                    isEditingHotspot={isEditingHotspot}
                    setIsEditingHotspot={setIsEditingHotspot}
                    clickedPointCoordinate={clickedPointCoordinate}
                    setImgSrc={setImgSrc}
                    isMobile={isMobile}
                />
               )}  
                <div style={{ position: "absolute", right: '30px', bottom: '30px' }}>
                    <Button
                        label={"Next"}
                        width="10vw"
                        onClick={onNext}
                        fontWeight="700"
                    />
                </div>
                <div className="carousel-wrapper">
                    {!isMobile && showImages && (
                        <div className="slider-container">
                            <div className="arrows arrow-left" onClick={() => scrollLeft(50)}>
                                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: "22px", cursor: "pointer" }}
                                />
                            </div>
                            <ImageList ref={sliderRef}>{renderImageList()}</ImageList>
                            <div className="arrows arrow-right" onClick={() => scrollRight(50)}>
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    style={{ fontSize: "22px", cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    )}
                    {!isMobile && showCarousel && <div className="up-arrow">
                        <img src={staticImage.UPARROW} alt="Show hide carousel" width="30" onClick={() => setShowImages(!showImages)} style={{ transform: `rotate(${showImages ? '180deg' : '0deg'})` }} />
                    </div>}
                </div>
                <>
                    <ControlBar
                        onPlay={() => toggleAutoplay(true)}
                        onPause={() => toggleAutoplay(false)}
                        onNext={() => nextMove()}
                        onPrev={() => prevMove()}
                        isMobile={isMobile}
                        isAutoPlayRunning={isAutoPlayRunning}
                        onZoomIn={() => rangePlus()}
                        onZoomOut={() => rangeMinus()}
                    />
                </>
            </>
            {/* {
            isAddingHotspot ?
                <div style={{
                    position: 'absolute', top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', minWidth: 400, width: '30vw',
                    backgroundColor: '#fff',
                    // border: '2px solid #000',
                    borderRadius: '12px',
                    boxShadow: '2px 2px 12px rgb(0 0 0 / 40%)',
                    padding: '16px', 
                    minHeight: '40vh'}}>
                    <HotspotAddModal stage='SELECT' modalOpen={true} setIsAddingHotspot={setIsAddingHotspot}/>
                </div> : ''
            } */}
        </>
    )
})

const ImageList = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

Viewer.defaultProps = {
    className: undefined,
    style: undefined,
    images: "numbered",
    pin: undefined,
    format: undefined,
    location: "./images",
    count: 0,
    draggable: true,
    hintOnStartup: true,
    hintText: null,
    autoplay: false,
    autoplaySpeed: 50,
    stopAutoplayOnClick: false,
    stopAutoplayOnMouseEnter: false,
    resumeAutoplayOnMouseLeave: false,
    touch: true,
    mousewheel: false,
    inverse: true,
    dragInterval: 1,
    touchDragInterval: 2,
    mouseleaveDetect: false,
    showHotspotControlBar: true,

    renderPin: undefined,

    onHintHide: () => { },
    onAutoplayStart: () => { },
    onAutoplayStop: () => { },
    onNextMove: () => { },
    onPrevMove: () => { },
    onNextFrame: () => { },
    onPrevFrame: () => { },
    onDragStart: () => { },
    onDragEnd: () => { },
    onFrameChange: () => { },
    onRecordStart: () => { },
    onRecordStop: () => { },
    onPinClick: () => { },
};

export default Viewer;