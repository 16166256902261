import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faArrowsAlt,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";

const ControlBar = ({
  onPlay,
  onPause,
  onNext,
  onPrev,
  onFullScreen,
  showFullScreenButton,
  isMobile,
  isAutoPlayRunning,
  onZoomIn,
  onZoomOut
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    setIsPlaying(isAutoPlayRunning);
  },[isAutoPlayRunning])

  const playHandler = () => {
    setIsPlaying(true);
    onPlay();
  };

  const pauseHandler = () => {
    setIsPlaying(false);
    onPause();
  };

  return (
    <div
      className={`Viewer-control-bar ${styles["Viewer-control-bar"]} ${
        isMobile ? "mobile-view" : ""
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={`${styles["Viewer-control-bar-buttons-wrapper"]}`} >
        <a className={`${styles["Viewer-control-button"]}`} onClick={onZoomIn}>
          <ZoomInIcon
            icon={faForward}
            style={{ cursor: "pointer", fontSize: '22px' }}
          />
        </a>
      </div>
      <div className={`${styles["Viewer-control-bar-buttons-wrapper"]}`} >
      <a className={`${styles["Viewer-control-button"]}`} onClick={onPrev}>
        <FontAwesomeIcon
          icon={faBackward}
          style={{ cursor: "pointer" }}
        />
        
      </a>
      </div>
      {!isPlaying && (
        <div className={`${styles["Viewer-control-bar-buttons-wrapper"]}`} style={{ borderColor: '#FFBF00'}}>
        <a
          className={`${styles["Viewer-control-button", "Viewer-control-play-pause-button"]}`}
          onClick={playHandler}
        >
          <FontAwesomeIcon
            icon={faPlay}
            style={{ cursor: "pointer" }}
          />
          
        </a>
        {/* <p className={`${styles["Viewer-control-button-title"]}`}>Play</p> */}
        </div>
      )}
      {(isPlaying)&& (
        <div className={`${styles["Viewer-control-bar-buttons-wrapper"]}`} style={{ background: '#FFBF00'}}>
        <a
          className={`${styles["Viewer-control-button", "Viewer-control-play-pause-button"]}`}
          onClick={pauseHandler}
        >
          <FontAwesomeIcon
            icon={faPause}
            style={{cursor: "pointer" }}
          />
        </a>
        </div>
      )}
      <div className={`${styles["Viewer-control-bar-buttons-wrapper"]}`} >
      <a className={`${styles["Viewer-control-button"]}`} onClick={onNext}>
        <FontAwesomeIcon
          icon={faForward}
          style={{  cursor: "pointer" }}
        />
        
      </a>
      </div>
      <div className={`${styles["Viewer-control-bar-buttons-wrapper"]}`} >
      <a className={`${styles["Viewer-control-button"]}`} onClick={onZoomOut}>
        <ZoomOutIcon
          icon={faForward}
            style={{ cursor: "pointer", fontSize: '22px' }}
        />
      </a>
      </div>

      {showFullScreenButton && (
        <a
          className={`${styles["Viewer-control-button"]}`}
          onClick={onFullScreen}
        >
          <FontAwesomeIcon
            icon={faArrowsAlt}
            style={{ cursor: "pointer" }}
          />
        </a>
      )}
    </div>
  );
};

export default ControlBar;
