import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import styled from "styled-components";
import staticImage from "../../assets/images";
import useInterval from "./hooks/useInterval";
import ControlBar from "./components/ControlBar";
import Pins from "./components/Pins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
faAngleRight,
faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
class ViewerUtils {
  static isValidProps = ({ images, format, location }) => {
    let isValid = true;
    if (!images && !format) {
      console.error(
        "'format' property is missing or invalid. Image format must be provided for 'numbered' property."
      );
      isValid = false;
    }
    if (images === "numbered" && !location) {
      console.error(
        "'location' property is missing or invalid. Image location must be provided for 'numbered' property."
      );
      isValid = false;
    }
    return isValid;
  };

  static normalizedImages = (images, format, location, count) => {
    if (images === "numbered") {
      return Array.apply(null, { length: count }).map((_a, index) => {
        return `${location}/${index + 1}.${format.toLowerCase()}`;
      });
    }
    return images;
  };

  static uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
}

const Viewer = forwardRef(
  (
    {
      className,
      style,
      images,
      originalResolution,
      pins: propPins,
      pinss,
      format,
      location,
      count,
      draggable,
      hintOnStartup,
      hintText,
      autoplay,
      autoplaySpeed,
      stopAutoplayOnClick,
      stopAutoplayOnMouseEnter,
      resumeAutoplayOnMouseLeave,
      touch,
      mousewheel,
      inverse,
      dragInterval,
      touchDragInterval,
      mouseleaveDetect,
      showControlBar,
      renderPin,
      onHintHide,
      onAutoplayStart,
      onAutoplayStop,
      onNextMove,
      onPrevMove,
      onNextFrame,
      onPrevFrame,
      onDragStart,
      onDragEnd,
      onFrameChange,
      onRecordStart,
      onRecordStop,
      onPinClick,
      fullScreen,
      handleFullScreenChange,
      showFullScreenButton,
      showImages: showCarousel,
    },
    ref
  ) => {
    const [moveBuffer, setMoveBuffer] = useState([]);
    const [hintVisible, setHintVisible] = useState(hintOnStartup);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [pins, setPins] = useState(propPins || []);
    const [isFullScreen, setIsFullScreen] = useState(fullScreen);
    const [zoom, setZoom] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const sliderRef = useRef(null);
    const [sliderSelected, setSliderSelected] = useState(null);
    const [angle, setAngle] = useState(0);
    const [isVideo, setIsVideo] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const [showInterior, setShowInterior] = useState(false);

    const _count = Array.isArray(images) ? images.length : Number(count);
    const _images = ViewerUtils.normalizedImages(
      images,
      format,
      location,
      _count
    );
    const _viewerImageRef = useRef(null);
    const _draggable = !isRecording && draggable;
    const rangePlus = () => setZoom((z) => (z + 0.1 > 3 ? 3 : z + 0.1));
    const rangeMinus = () => setZoom((z) => (z - 0.1 < 1 ? 1 : z - 0.1));

    const hideHint = () => {
      toggleAutoplay(false);
      setHintVisible(false);
      onHintHide();
    };

    const getWindowDimensions = useCallback(() => {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    });

    useEffect(() => {
      function handleResize() {
        const { width } = getWindowDimensions();
        width < 760 ? setIsMobile(true) : setIsMobile(false);
      }

      handleResize();

      if (window) window.addEventListener("resize", handleResize);
      return () =>
        window ? window.removeEventListener("resize", handleResize) : null;
    }, []);

    const toggleFullScreen = useCallback(() => {
      setIsFullScreen(!isFullScreen);
      handleFullScreenChange();
    });

    const changeCurrentImage = (imageIndex) => {
      setCurrentImageIndex(imageIndex);
      setSliderSelected(imageIndex);
      changeAngle(imageIndex);
    };

    const changeAngle = useCallback(
      (index) => {
        let angle = (360 / _images.length) * index;
        setAngle(angle);
      },
      [_images, setAngle]
    );

    const prevFrame = useCallback(() => {
      const newIndex =
        currentImageIndex >= _count - 1 ? 0 : currentImageIndex + 1;
      setCurrentImageIndex(newIndex);
      changeAngle(newIndex);
      onNextFrame();
      onFrameChange(newIndex);
    }, [_count, currentImageIndex, onFrameChange, onNextFrame, changeAngle]);

    const nextFrame = useCallback(() => {
      const newIndex =
        currentImageIndex <= 0 ? _count - 1 : currentImageIndex - 1;
      setCurrentImageIndex(newIndex);
      changeAngle(newIndex);
      onPrevFrame();
      onFrameChange(newIndex);
    }, [_count, currentImageIndex, onFrameChange, onPrevFrame, changeAngle]);

    const nextMove = useCallback(() => {
      onNextMove();
      return inverse ? prevFrame() : nextFrame();
    }, [inverse, nextFrame, onNextMove, prevFrame]);

    const prevMove = useCallback(() => {
      onPrevMove();
      return inverse ? nextFrame() : prevFrame();
    }, [inverse, nextFrame, onPrevMove, prevFrame]);

    const rotateViewerImage = useCallback(
      (e) => {
        const interval = e.touches ? touchDragInterval : dragInterval;
        const eventX = e.touches ? Math.round(e.touches[0].clientX) : e.clientX;
        const coord = eventX - _viewerImageRef.current.offsetLeft;
        let newMoveBufffer = moveBuffer;
        if (moveBuffer.length < 2) {
          newMoveBufffer = moveBuffer.concat(coord);
        } else {
          newMoveBufffer = [moveBuffer[1], coord];
        }
        setMoveBuffer(newMoveBufffer);
        const threshold = !(coord % interval);
        const oldMove = newMoveBufffer[0];
        const newMove = newMoveBufffer[1];
        if (threshold && newMove < oldMove) {
          nextMove();
        } else if (threshold && newMove > oldMove) {
          prevMove();
        }
      },
      [dragInterval, moveBuffer, nextMove, prevMove, touchDragInterval]
    );

    const resetMoveBuffer = () => setMoveBuffer([]);

    const startDragging = useCallback(() => {
      setIsDragging(true);
      onDragStart();
    }, [onDragStart]);

    const stopDragging = useCallback(() => {
      setIsDragging(false);
      onDragEnd();
    }, [onDragEnd]);

    const toggleAutoplay = useCallback(
      (state) => {
        setIsAutoPlayRunning(state);
        return state ? onAutoplayStart() : onAutoplayStop();
      },
      [onAutoplayStart, onAutoplayStop]
    );

    const toggleRecording = (state) => {
      setIsRecording(state);
      return state ? onRecordStart(pins) : onRecordStop(pins);
    };

    // handlers
    const imageViewerMouseDownHandler = (e) => {
      if (_draggable) {
        if (e.preventDefault) e.preventDefault();
        startDragging();
        rotateViewerImage(e);
      }

      if (isAutoPlayRunning && stopAutoplayOnClick) {
        toggleAutoplay(false);
      }
    };

    const imageViewerMouseUpHandler = (e) => {
      if (_draggable) {
        if (e.preventDefault) e.preventDefault();
        stopDragging();
        resetMoveBuffer();
      }
    };

    const imageViewerMouseMoveHandler = (e) => {
      if (_draggable && isDragging) {
        rotateViewerImage(e);
      }
    };

    const imageViewerMouseLeaveHandler = () => {
      if (_draggable) resetMoveBuffer();
      if (!isAutoPlayRunning && resumeAutoplayOnMouseLeave) {
        toggleAutoplay(true);
      }
      if (mouseleaveDetect) {
        stopDragging();
        resetMoveBuffer();
      }
    };

    const imageViewerMouseEnterHandler = () => {
      if (isAutoPlayRunning && stopAutoplayOnMouseEnter) {
        toggleAutoplay(false);
      }
    };

    const imageViewerWheelHandler = useCallback(
      (e) => {
        if (mousewheel) {
          if (e.preventDefault) e.preventDefault();
          e.deltaY / 120 > 0 ? nextMove() : prevMove();
        }
      },
      [mousewheel, nextMove, prevMove]
    );

    const imageViewerTouchStartHandler = useCallback(
      (e) => {
        if (touch) {
          if (e.preventDefault) e.preventDefault();
          startDragging();
          rotateViewerImage(e);
        }

        if (isAutoPlayRunning && stopAutoplayOnClick) {
          toggleAutoplay(false);
        }
      },
      [
        isAutoPlayRunning,
        rotateViewerImage,
        startDragging,
        stopAutoplayOnClick,
        toggleAutoplay,
        touch,
      ]
    );

    const imageViewerTouchMoveHandler = useCallback(
      (e) => {
        if (touch) {
          if (e.preventDefault) e.preventDefault();
          rotateViewerImage(e);
        }
      },
      [rotateViewerImage, touch]
    );

    const imageViewerTouchEndHandler = useCallback(
      (e) => {
        if (touch) {
          stopDragging();
          resetMoveBuffer();
        }

        if (!isAutoPlayRunning && resumeAutoplayOnMouseLeave) {
          toggleAutoplay(true);
        }
      },
      [
        isAutoPlayRunning,
        resumeAutoplayOnMouseLeave,
        stopDragging,
        toggleAutoplay,
        touch,
      ]
    );

    const imageViewerClickHandler = (e) => {
      if (!isRecording) {
        const clientX = e.clientX;
        const clientY = e.clientY;
        const viewerWidth = _viewerImageRef.current.clientWidth;
        const viewerHeight = _viewerImageRef.current.clientHeight;
        const x = (clientX / viewerWidth).toFixed(6);
        const y = (clientY / viewerHeight).toFixed(6);
        const pin = { id: ViewerUtils.uid(), frameId: currentImageIndex, x, y };
        const newPins = pins.concat(pin);
        setPins(newPins);
      }
    };

    const pinDoubleClickHandler = (pin) => {
      if (isRecording) {
        const newPins = pins.filter((item) => item.id !== pin.id);
        setPins(newPins);
      }
    };

    const pinClickHandler = (pin) => {
      if (!isRecording) {
         onPinClick(pin);
      }
    };

    useEffect(() => {
      const viewerRef = _viewerImageRef.current;
      viewerRef.addEventListener("touchstart", imageViewerTouchStartHandler, {
        passive: false,
      });
      viewerRef.addEventListener("touchmove", imageViewerTouchMoveHandler, {
        passive: false,
      });
      viewerRef.addEventListener("touchend", imageViewerTouchEndHandler, {
        passive: false,
      });
      viewerRef.addEventListener("wheel", imageViewerWheelHandler, {
        passive: false,
      });

      return () => {
        viewerRef.removeEventListener(
          "touchstart",
          imageViewerTouchStartHandler
        );
        viewerRef.removeEventListener("touchmove", imageViewerTouchMoveHandler);
        viewerRef.removeEventListener("touchend", imageViewerTouchEndHandler);
        viewerRef.removeEventListener("wheel", imageViewerWheelHandler);
      };
    }, [
      imageViewerTouchEndHandler,
      imageViewerTouchMoveHandler,
      imageViewerTouchStartHandler,
      imageViewerWheelHandler,
    ]);

    useEffect(() => {
      toggleAutoplay(true);
      setTimeout(
        function () {
          //Start the timer
          toggleAutoplay(false); //After 1 second, set render to true
        }.bind(this),
        12000
      );
    }, [toggleAutoplay]);

    useEffect(() => {
      if (autoplay) {
        toggleAutoplay(autoplay);
      }
    }, [autoplay, toggleAutoplay]);

    useInterval(
      () => {
        nextMove();
      },
      isAutoPlayRunning ? autoplaySpeed : null
    );

    useImperativeHandle(ref, () => ({
      toggleRecording: (state) => toggleRecording(state),
      toggleAutoplay: (state) => toggleAutoplay(state),
      next: () => nextMove(),
      prev: () => prevMove(),
    }));

    // render component helpers
    const renderImages = () =>
      _images.map((src, index) => (
        <Img
          zoom={zoom}
          key={index}
          src={src}
          className={`list-image ${styles["Viewer-viewer-image"]} ${
            currentImageIndex === index
              ? styles["Viewer-viewer-image-shown"]
              : styles["Viewer-viewer-image-hidden"]
          }`}
          alt=""
        />
      ));
    const renderImageList = () => {
      let degree = -45;
      return _images.map((src, index) => {
        if (index % Math.ceil(_images.length / 8) == 0) {
          degree += 45;
          return (
            <div
              key={index}
              className={`single-image-container ${
                sliderSelected === index ? "selected-images" : ""
              }`}
            >
              <Img
                key={index}
                src={src}
                alt=""
                className={`scroll-images`}
                onClick={() => changeCurrentImage(index)}
              />
               <div className="list-image-title">Exterior {degree}° </div>
            </div>
          );
        }
      });
    };

    const renderHint = () => (
      <div
        className={`${styles["Viewer-hint-overlay"]}`}
        onClick={hideHint}
        onTouchStart={hideHint}
      >
        <div
          className="spin360-swipe-prompt"
        >
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            viewBox="0 0 140 40"
          >
            <path d="M85.5022 38.39L75.9372 28.825C75.8119 28.7001 75.6421 28.6299 75.4652 28.63H75.4612C75.373 28.6307 75.2858 28.6489 75.2046 28.6833C75.1235 28.7178 75.0499 28.768 74.9882 28.831L70.4692 33.467L61.8492 11.861L83.4522 20.48L78.8072 24.986C78.7433 25.0473 78.6924 25.1208 78.6574 25.2021C78.6223 25.2833 78.6039 25.3708 78.6031 25.4593C78.6024 25.5478 78.6193 25.6356 78.653 25.7175C78.6867 25.7993 78.7364 25.8736 78.7992 25.936L88.3792 35.516L85.5022 38.39ZM89.7922 35.044L80.2212 25.471L85.1192 20.721C85.1988 20.6435 85.2578 20.5474 85.2908 20.4413C85.3238 20.3352 85.3298 20.2226 85.3082 20.1136C85.2867 20.0047 85.2382 19.9028 85.1673 19.8173C85.0964 19.7318 85.0053 19.6653 84.9022 19.624L70.7822 13.989C71.1332 12.919 71.3222 11.805 71.3222 10.667C71.3212 4.785 66.5352 0 60.6542 0C54.7722 0 49.9862 4.785 49.9862 10.667C49.9862 16.547 54.7722 21.333 60.6542 21.333C60.8265 21.3263 60.9895 21.2532 61.109 21.1289C61.2286 21.0047 61.2954 20.8389 61.2954 20.6665C61.2954 20.4941 61.2286 20.3283 61.109 20.2041C60.9895 20.0798 60.8265 20.0067 60.6542 20C55.5072 20 51.3202 15.813 51.3202 10.667C51.3202 5.52 55.5072 1.333 60.6542 1.333C65.8002 1.333 69.9872 5.52 69.9872 10.667C69.9872 11.635 69.8312 12.583 69.5412 13.495L60.9012 10.047C60.78 9.99853 60.6472 9.98668 60.5194 10.0129C60.3915 10.0392 60.2741 10.1024 60.1818 10.1947C60.0895 10.287 60.0263 10.4043 60.0001 10.5322C59.9739 10.66 59.9857 10.7928 60.0342 10.914L69.6102 34.914C69.651 35.0175 69.7172 35.1092 69.8026 35.1805C69.888 35.2519 69.99 35.3006 70.0992 35.3223C70.2084 35.344 70.3212 35.3379 70.4275 35.3047C70.5337 35.2714 70.6299 35.2121 70.7072 35.132L75.4712 30.245L85.0302 39.805C85.0919 39.8671 85.1653 39.9164 85.2461 39.95C85.3269 39.9837 85.4136 40.001 85.5012 40.001C85.5887 40.001 85.6754 39.9837 85.7562 39.95C85.8371 39.9164 85.9105 39.8671 85.9722 39.805L89.7922 35.987C89.9167 35.8617 89.9866 35.6922 89.9866 35.5155C89.9866 35.3388 89.9167 35.1693 89.7922 35.044Z"></path>
            <path d="M139.923 21.775C139.917 21.79 139.905 21.799 139.897 21.812C139.866 21.8781 139.824 21.9386 139.773 21.991L139.768 21.998L127.768 33.332C127.639 33.4531 127.468 33.5182 127.291 33.513C127.159 33.5099 127.031 33.4675 126.923 33.3912C126.815 33.3149 126.732 33.2082 126.685 33.0847C126.638 32.9612 126.629 32.8265 126.659 32.6978C126.689 32.569 126.757 32.4522 126.853 32.362L137.633 22.18H100.643C100.556 22.1801 100.469 22.1629 100.388 22.1294C100.307 22.096 100.234 22.0469 100.172 21.985C100.078 21.8921 100.014 21.7733 99.9878 21.6438C99.9616 21.5142 99.9746 21.3798 100.025 21.2577C100.076 21.1356 100.161 21.0313 100.272 20.9581C100.382 20.885 100.511 20.8463 100.643 20.847H137.633L126.853 10.666C126.753 10.5766 126.682 10.4589 126.65 10.3285C126.617 10.198 126.625 10.0609 126.672 9.935C126.703 9.85138 126.75 9.77487 126.812 9.71008C126.873 9.64528 126.946 9.59355 127.028 9.558C127.151 9.50326 127.288 9.48715 127.42 9.5118C127.552 9.53644 127.673 9.60068 127.768 9.696L139.768 21.029L139.773 21.036C139.824 21.0883 139.866 21.1488 139.897 21.215C139.905 21.228 139.917 21.238 139.923 21.252C139.959 21.3345 139.978 21.4235 139.978 21.5135C139.978 21.6035 139.959 21.6925 139.923 21.775Z"></path>
            <path d="M2.34218 20.18H39.3322C39.5091 20.18 39.6797 20.2503 39.8048 20.3754C39.9299 20.5004 40.0002 20.6701 40.0002 20.847C40.0002 21.0239 39.9299 21.1936 39.8048 21.3186C39.6797 21.4437 39.5101 21.514 39.3332 21.514H2.34318L13.1242 31.696C13.2528 31.8175 13.3279 31.9851 13.333 32.1619C13.338 32.3388 13.2726 32.5104 13.1512 32.639C13.0297 32.7676 12.8621 32.8427 12.6853 32.8478C12.5084 32.8529 12.3368 32.7875 12.2082 32.666L0.208179 21.332L0.203178 21.324C0.152067 21.2719 0.109841 21.2118 0.0781784 21.146C0.0721784 21.133 0.0591783 21.123 0.0531783 21.109C0.0180838 21.0261 0 20.937 0 20.847C0 20.757 0.0180838 20.6679 0.0531783 20.585C0.0581783 20.572 0.0721784 20.561 0.0781784 20.548C0.109841 20.4822 0.152067 20.4221 0.203178 20.37L0.208179 20.362L12.2082 9.029C12.3387 8.92012 12.5059 8.8651 12.6755 8.87516C12.8452 8.88521 13.0047 8.95957 13.1215 9.0831C13.2382 9.20663 13.3035 9.37003 13.304 9.54002C13.3045 9.71 13.2402 9.87378 13.1242 9.998L2.34218 20.18Z"></path>
          </svg>
        </div>
      </div>
    );

    const scrollRight = (scrollOffset) => {
      sliderRef.current.scrollLeft += scrollOffset;
    };
    const scrollLeft = (scrollOffset) => {
      sliderRef.current.scrollLeft -= scrollOffset;
    };

    const generateViewerClassName = () => {
      let classNameStr = styles["Viewer-viewer"];
      if (_draggable) classNameStr += " " + styles["Viewer-draggable-true"];
      if (isRecording) classNameStr += " " + styles["Viewer-recording-true"];
      if (touch) classNameStr += " " + styles["Viewer-touch-true"];
      if (mousewheel) classNameStr += " " + styles["Viewer-mousewheel-true"];
      if (hintOnStartup)
        classNameStr += " " + styles["Viewer-hintOnStartup-true"];
      if (className) classNameStr += " " + className;
      return classNameStr;
    };

    if (!ViewerUtils.isValidProps({ images, format, location })) return null;
    return (
      <>
      <div className="top-nav">
      {/* <span className={`${!showInterior ? "Viewer-toggle-side-active" : "Viewer-toggle-side"}`} onClick={()=>setShowInterior(false)}>Exterior</span>
      <span className={`${showInterior ? "Viewer-toggle-side-active" : "Viewer-toggle-side"}`} onClick={()=>setShowInterior(true)}>Interior</span> */}
      
        {/* <div className={`exterior ${isVideo ? "" : "selected"}`} onClick={()=>setIsVideo(!isVideo)}>
          <span>Exterior</span>
        </div>
        <div className={`interior ${isVideo ? "selected" : ""}`} onClick={()=>setIsVideo(!isVideo)}>
          <span>Interior</span>
        </div> */}
      </div>
        <ImgContainer
          zoomHeight={isFullScreen ? window.innerHeight : "auto"}
          viewerHeight={window.innerHeight*0.97}
          className={generateViewerClassName()}
          ref={_viewerImageRef}
          onMouseDown={imageViewerMouseDownHandler}
          onMouseUp={imageViewerMouseUpHandler}
          onMouseMove={imageViewerMouseMoveHandler}
          onMouseLeave={imageViewerMouseLeaveHandler}
          onMouseEnter={imageViewerMouseEnterHandler}
          onClick={imageViewerClickHandler}
        >
          {hintVisible && renderHint()}
          {/* {isVideo && (
            <Player src="https://cartagqa.s3.amazonaws.com/5_1_360Interior.mp4" autoPlay muted playsInline>
              <BigPlayButton position="center" style={{display: "none"}} />
            </Player>
          )} */}
          {!isVideo && _images?.length > 0 && renderImages()}
          {!isVideo && <><div
            className="spin360-radar"
            style={{
              transform: `rotate(${angle}deg)`,
            }}
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlSpace="preserve"
              viewBox="0 0 256 256"
              width="50"
              height="50"
            >
              <g>
                <circle
                  strokeOpacity="0.2"
                  stroke="#FFFFFF"
                  fillOpacity="0.4"
                  fill="#000000"
                  cx="128"
                  cy="128"
                  r="128"
                ></circle>
                <circle
                  cx="128"
                  cy="128"
                  r="110"
                  stroke="white"
                  strokeWidth="5"
                  fill="none"
                ></circle>
                <circle cx="128" cy="128" r="10" fill="white"></circle>
                <polygon
                  points="0,0 16,16 0,32"
                  fill="white"
                  transform="translate(128, 2) rotate(-90 0,16)"
                ></polygon>
              </g>
              <g transform="translate(128, 128) rotate(-135)">
                <path
                  d="M0,0 L0,95 A95,95 0 0,0 95,0 Z"
                  strokeWidth="2"
                  stroke="white"
                  fill="white"
                  mask="url(#m)"
                  transform="rotate(360)"
                ></path>
              </g>
            </svg>
          </div>
          <span className="spin360-radar-angle">{angle}&deg; </span></>}
          {!isVideo && !isMobile && showControlBar && (
            <ControlBar
              onPlay={() => toggleAutoplay(true)}
              onPause={() => toggleAutoplay(false)}
              onNext={() => nextMove()}
              onPrev={() => prevMove()}
              onZoomIn={() => rangePlus()}
              onZoomOut={() => rangeMinus()}
              onFullScreen={() => toggleFullScreen()}
              onRecordStart={() => toggleRecording(true)}
              onRecordStop={() => toggleRecording(false)}
              showFullScreenButton={showFullScreenButton}
            />
          )}

          {
            // <Pins
            //   pins={pinss}
            //   viewerWidth={_viewerImageRef?.current?.clientWidth}
            //   viewerHeight={_viewerImageRef?.current?.clientHeight}
            //   currentFrameId={currentImageIndex}
            //   onPinDoubleClick={pinDoubleClickHandler}
            //   onPinClick={pinClickHandler}
            //   renderPin={renderPin}
            //   isFullScreen={isFullScreen}
            //   originalResolution={originalResolution}
            // />
          }
        </ImgContainer>
        {isMobile && showControlBar && (
          <ControlBar
            isMobile={isMobile}
            onPlay={() => toggleAutoplay(true)}
            onPause={() => toggleAutoplay(false)}
            onNext={() => nextMove()}
            onPrev={() => prevMove()}
            onZoomIn={() => rangePlus()}
            onZoomOut={() => rangeMinus()}
            onFullScreen={() => toggleFullScreen()}
            onRecordStart={() => toggleRecording(true)}
            onRecordStop={() => toggleRecording(false)}
          />
        )}
        {showImages && (
          <div className="slider-container">
            <div className="arrows arrow-left" onClick={() => scrollLeft(50)}>
              {/* <img src={staticImage.PREV} alt="Previous" width="50" /> */}
              <FontAwesomeIcon
                icon={faAngleLeft}
                style={{ fontSize: "22px", cursor: "pointer" }}
              />
            </div>
            <ImageList ref={sliderRef}>{renderImageList()}</ImageList>
            <div className="arrows arrow-right" onClick={() => scrollRight(50)}>
              {/* <img src={staticImage.NEXT} alt="Next" width="50" /> */}
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{ fontSize: "22px", cursor: "pointer" }}
              />
            </div>
          </div>
        )}
        {showCarousel && <div className="up-arrow" onClick={()=> setShowImages(!showImages)}>
          <img src={staticImage.UPARROW} alt="Show hide carousel" width="30" style={{transform: `scaleY(${showImages ? '-1':'1'})`}}/>
        </div>}
      </>
    );
  }
);

Viewer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  pins: PropTypes.array,
  format: PropTypes.string,
  location: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  draggable: PropTypes.bool,
  hintOnStartup: PropTypes.bool,
  hintText: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  stopAutoplayOnClick: PropTypes.bool,
  stopAutoplayOnMouseEnter: PropTypes.bool,
  resumeAutoplayOnMouseLeave: PropTypes.bool,
  touch: PropTypes.bool,
  mousewheel: PropTypes.bool,
  inverse: PropTypes.bool,
  dragInterval: PropTypes.number,
  touchDragInterval: PropTypes.number,
  mouseleaveDetect: PropTypes.bool,
  showControlBar: PropTypes.bool,

  renderPin: PropTypes.func,

  onHintHide: PropTypes.func,
  onAutoplayStart: PropTypes.func,
  onAutoplayStop: PropTypes.func,
  onNextMove: PropTypes.func,
  onPrevMove: PropTypes.func,
  onNextFrame: PropTypes.func,
  onPrevFrame: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onFrameChange: PropTypes.func,
  onRecordStart: PropTypes.func,
  onRecordStop: PropTypes.func,
  onPinClick: PropTypes.func,
};

Viewer.defaultProps = {
  className: undefined,
  style: undefined,
  images: "numbered",
  pin: undefined,
  format: undefined,
  location: "./images",
  count: undefined,
  draggable: true,
  hintOnStartup: true,
  hintText: null,
  autoplay: false,
  autoplaySpeed: 50,
  stopAutoplayOnClick: false,
  stopAutoplayOnMouseEnter: false,
  resumeAutoplayOnMouseLeave: false,
  touch: true,
  mousewheel: false,
  inverse: true,
  dragInterval: 1,
  touchDragInterval: 2,
  mouseleaveDetect: false,
  showControlBar: false,

  renderPin: undefined,

  onHintHide: () => {},
  onAutoplayStart: () => {},
  onAutoplayStop: () => {},
  onNextMove: () => {},
  onPrevMove: () => {},
  onNextFrame: () => {},
  onPrevFrame: () => {},
  onDragStart: () => {},
  onDragEnd: () => {},
  onFrameChange: () => {},
  onRecordStart: () => {},
  onRecordStop: () => {},
  onPinClick: () => {},
};

const ImgContainer = styled.div`
 height: ${(props) => props.viewerHeight}px;
`;

const Img = styled.img`
  transform: scale(${(props) => props.zoom || 1});
`;

const ImageList = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
`;

export default Viewer;
