import React from 'react'
import Button from "@mui/material/Button";

import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import EditLocationTwoToneIcon from '@mui/icons-material/EditLocationTwoTone';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import './HotspotControlBarStyles.css';

const hotspotControlBar = ({
  onZoomIn,
  onZoomOut,
  zoom = 1,
  setZoom,
  isAddingHotspot,
  setIsAddingHotspot,
  setClickedPointCoordinate,
  isEditingHotspot,
  setIsEditingHotspot,
  clickedPointCoordinate,
  setImgSrc,
  isMobile

}) => {
  const background_color = '#ffbf00';
  const handleHotspot = () => {
    setClickedPointCoordinate(null);
    setImgSrc('');
    setIsAddingHotspot(prev => !prev);
    setIsEditingHotspot(false);
  }

  const handleEditHotspot = () => {
    setIsEditingHotspot(prev => !prev);
    setIsAddingHotspot(false)
  }

  return (
    <div style={{ position: 'absolute', left: '20px', top: '20px' }}>
      <div className='control-bar-wrapper'>
        <Button
          className={`control-bar-btn`}
          style={{ background: `${isEditingHotspot ? background_color : '#333'}` }}
          variant={isEditingHotspot ? 'contained' : 'outlined'}
          onClick={() => handleEditHotspot()}>
          <EditLocationTwoToneIcon /> {!isMobile?(isEditingHotspot?'Editing..':'Edit Hotspot'):''}
        </Button>
        <Button
          className={`control-bar-btn`}
          style={{ background: `${isAddingHotspot ? background_color : '#333'}` }}
          variant={isAddingHotspot ? 'contained' : 'outlined'}
          onClick={() => handleHotspot()}>
          {isAddingHotspot ? <LocationOnTwoToneIcon />: <AddLocationIcon />}
          {!isMobile?(isAddingHotspot ? 'Adding..': 'Add Hotspot'):''}
        </Button>
        {/* <Button className='control-bar-btn' variant='outlined' style={{
          pointerEvents: `${clickedPointCoordinate != null ? 'none' : 'auto'}`, touchAction: `${clickedPointCoordinate != null ? 'none' : 'auto'}`
        }} onClick={() => setZoom(1)}><RotateLeftIcon />{!isMobile?'Reset':''}</Button> */}
        {/* <Button className='control-bar-btn' variant='outlined' style={{
          pointerEvents: `${clickedPointCoordinate != null ? 'none' : 'auto'}`, touchAction: `${clickedPointCoordinate != null ? 'none' : 'auto'}`
        }} onClick={() => onZoomIn()}><ZoomInIcon /> {!isMobile?'Zoom In':''}</Button>
        <Button className='control-bar-btn' variant='outlined' style={{
          pointerEvents: `${clickedPointCoordinate != null ? 'none' : 'auto'}`, touchAction: `${clickedPointCoordinate != null ? 'none' : 'auto'}` }} onClick={() => onZoomOut()}><ZoomOutIcon /> {!isMobile?'Zoom Out':''}</Button> */}
      </div>
    </div>
  )
}

hotspotControlBar.defaultProps = {
  zoom: 1,
  isAddingHotspot: false,
  isEditingHotspot: false,
  onZoomIn: () => { },
  onZoomOut: () => { },
  setZoom: () => { },
  setIsAddingHotspot: () => { },
  setIsEditingHotspot: () => { },
  setClickedPointCoordinate: () => { },
  setImgSrc: () => { }
}

export default hotspotControlBar