import React, { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import images from "../../assets/images";
import shareButton from '../../assets/images/share/share-button.svg'
import styled from 'styled-components';
import Sharebox from '../../components/viewer/components/Sharebox'
import cloneDeep from 'lodash.clonedeep';
// import car_hotspot_data from './sample_assessment/car_parts_info.json';

import Viewer from './Viewer'
import './index.css'

const Hotspot = (props) => {
    const [carlistingUrlsArray, setCarListingUrlsArray] = useState([])
    const [carlistingGyroData, setCarListingGyroData] = useState([])
    const [numberOfImages, setNumberOfImages] = useState(1);
    const [showShareBox,setShowShareBox] = useState(false)
    const [imagePath, setImagePath] = useState(
        "https://d1iddyy6okui5n.cloudfront.net/360view"
    );
    const vin_no = window.location.search ? window.location.search : "";
    // const [uploading, setUploading] = useState(false);
    const [pins, setPins] = useState([]); 
    const [showImages, setShowImages] = useState(false);
    const [showViewer, setShowViewer] = useState(true);
    const [hotspotPins, setHotspotPins] = useState([]);
    const [oldCoOrdinates, setOldCoOrdinates] = useState([]);
    const [originalResolution, setOriginalResolution] = useState(null);
    const [processedResolution, setProcessedResolution] = useState(null);
    const [fullScreen, setFullScreen] = useState(true);
    const [companyLogo, setCompanyLogo] = useState();

    // let zip = new JSZip();

    console.log("CARLISTING", carlistingUrlsArray, props)

    // const onNext = () => {
    // console.log("HOTSPOT PROPS", props);
    // console.log("Creating zip for carlisting");
    // for (let i = 0; i < carlistingUrlsArray.length; i++) {
    //     console.log(`Zipping ${i+1}th image`, carlistingUrlsArray[i]);
    //     zip.file(`${i + 1}.png`, carlistingUrlsArray[i].split(",")[1], {
    //     base64: true,
    //     });
    // }
    // console.log("PROPS", props);
    // zip.file("GyroscopeDataWeb.json", JSON.stringify(carlistingGyroData));
    // console.log("Zipped Gyroscope data", zip);
    // zip.generateAsync({
    //     type: "blob",
    //     compression: "DEFLATE",
    //     compressionOptions: {
    //         level: 9,
    //     },
    // })
    // .then((content) => {
    //     const tags = [];
    //     tags.push({
    //         co_ordinates: [],
    //         tag_type: "DAMAGE_TYPE",
    //         damage_types: ["No damage"],
    //     });

    //     tags.push({
    //         tag_type: "AREA",
    //         value: props.area,
    //     });

    //     if (props.subArea) {
    //         tags.push({
    //             tag_type: "SUB_AREA",
    //             value: props.subArea,
    //         });
    //     };

    //     let tempArr = hotspotPins;
    //     let tagged_files = [];

    //     for(let i=0; i<tempArr.length; i++){
    //         if(tempArr[i].length>0){
    //             let file_hotspot_obj = {};
    //             file_hotspot_obj = {...file_hotspot_obj, ...{"file_name": `${i+1}.png`}}
    //             let hotspot_tag = [];
    //             hotspot_tag = tempArr[i].map((pin, index) => {
    //                 let hotspotTagObj = {};
    //                 hotspotTagObj = {...hotspotTagObj, ...{"co_ordinates": pin.coordinates, "title": pin.subject, "description": pin.description, tag_type:"PIN"}};
    //                 if(pin.src!='data:,' || pin.src!=''){
    //                     hotspotTagObj = {...hotspotTagObj, ...{"hotspot_img_url": pin.src}}
    //                 }
    //                 return hotspotTagObj;
    //             })
    //             file_hotspot_obj = {...file_hotspot_obj, ...{"tags": hotspot_tag}};
    //             tagged_files.push(file_hotspot_obj);
    //         }
    //     }

    //     console.log("Tagged_FILES", tagged_files);


    //     WaitingStatus.next(false);
    //     setUploading(true);
    //     GeneratePresignedUrlFor360$(
    //         companyData.otp,
    //         assessmentData.assessment_id,
    //         "b04d837c-3539-430e-b9ae-159dcbe1e96b",
    //         "ZIP",
    //         []
    //     )
    //     .then(async (response) => {
    //         let res = await response.json();
    //         console.log("Uploading the Image to the URL......", res);

    //         setToStore("360_id", res.id);
    //         try {
    //             await Upload360FilesToS3$(res.uploadUrl, content);
    //         } catch (err) {
    //             console.log(err);
    //         }

    //         // console.log("GOING TO NEXT PROPS 2", props, thumbnailArray, damageType);
    //         try {
    //         await Update360FileMetaDataArea$(
    //             companyData.otp,
    //             assessmentData.assessment_id,
    //             "b04d837c-3539-430e-b9ae-159dcbe1e96b",
    //             res.id,
    //             geoLocation,
    //             {
    //                 height: toImgHeight,
    //                 width: toImgWidth,
    //             },
    //             tagged_files,
    //             tags,
    //             true
    //         )
    //         .then(() => {
    //             setUploading(false);
    //             // setCaristingUrlsArray = [];
    //             // carlistingGyroData = [];
    //             props.nextAction({
    //                 ...props,
    //                 // damageType,
    //                 retake: props["retake"],
    //             });
    //             // idbRequest.delete_key("thumbnailArray");
    //             // idbRequest.delete_key("gyroArray");
    //         })
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     });
    //     });
    // }

    useEffect(() => {
        // const api = `${
        //   process.env.REACT_APP_API_HOST
        // }CarscanUserDto/getcarspinbyvin?vin_number=${vin_no.substring(1)}`;
        console.log("Location ", window.location.href)
        const core_file_id = window && window.location ? window.location.pathname.split('/')[2] : '';
        const vin_no = window && window.location ? window.location.pathname.split('/')[1] : '';
        console.log("Location id ", core_file_id, vin_no)
        let base_url = process.env.REACT_APP_API_HOST;

        let api = `${base_url}coreservice/public/threesixty`;
        if (vin_no) {
            api += `/${vin_no}`
        }
        if (core_file_id) {
            api += `/${core_file_id}`;
        }

        fetch(api, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((response) => {
                if (+response.progress_percentage < 100 || response.error) {
                    setShowViewer(false);
                    return
                }
                setImagePath(response?.base_path);
                setNumberOfImages(response?.no_of_files);
                setProcessedResolution(response?.processed_image_resolution);
                setOriginalResolution(response?.original_image_resolution);
                setPins(response?.tagged_files);
                setCompanyLogo(response.logo);
                let tempcarlistingarr = [...Array(response?.no_of_files)].map((e, index) => `${index+1}.${process.env.REACT_APP_IMAGE_EXTENSION||'png'}`);
                setCarListingUrlsArray(tempcarlistingarr);
                let arr = [...Array(response?.no_of_files)].map(() => []);// gives array of length of number of images with each element a blank array
                response['tagged_files'].map((file, index) => {
                    let file_name = file?.file_name;
                    let file_number = parseInt(file_name.split('.')[0])-1;
                    if(file?.tags.length>0){
                        arr[file_number] = file?.tags.map((pin) => {
                            let tempObj = {};
                            tempObj = { ...tempObj, ...{ 'co_ordinates': { 'x': parseInt(pin?.co_ordinates?.x), 'y': parseInt(pin?.co_ordinates?.y) }, 'subject': pin.title, 'description': pin.description, 'uid': Date.now().toString(36) + Math.random().toString(36).substring(2), 'frameId': file_number, 'src': `${(pin?.hotspot_image == null || pin?.hotspot_image ==undefined || pin.hotspot_image=='')?'data:,':pin.hotspot_image}`}};
                            return tempObj;
                        })
                    }
                })
                const deepClone = cloneDeep(arr)
                setOldCoOrdinates()
                setHotspotPins(deepClone);
                setTimeout(
                    function () {
                        //Start the timer
                        setShowImages({ render: true }); //After 1 second, set render to true
                    }.bind(this),
                    1500
                );
            })
            .catch((error) => {
                setShowViewer(false);
                console.log(error)
            });

        // setShowViewer(true);
        // setImagePath('./sample_assessment/');
        // setNumberOfImages(30);
        // setProcessedResolution(response?.processed_image_resolution);
        // setOriginalResolution(response?.original_image_resolution);
        // console.log(hotspot_data_json);
        // setCarListingUrlsArray(Object.keys(hotspot_data_json));
        setTimeout(
                    function () {
                        //Start the timer
                        setShowImages({ render: true }); //After 1 second, set render to true
                    }.bind(this),
                    1500
                );
    }, [vin_no]);

    return (<>
        <Box className="Wrapper">
            {
                !showViewer && (
                    <div style={{ marginTop: "10em" }}>
                        <h1 style={{ color: "red" }}>360° Viewer Not Available!</h1>
                        <p>Please try after sometime!</p>
                    </div>
                )
            }
            {!fullScreen && (
                <LogoWrapper>
                    <Logo src={images.LOGO} alt="logo" />
                </LogoWrapper>
            )}
            <Box className="image-viewer Viewer-full-screen">
                {carlistingUrlsArray.length > 0 ? 
                    <Viewer
                    pinss={pins}
                    carlistingUrlsArray={carlistingUrlsArray}
                    carlistingGyroData={carlistingGyroData}
                    setOldCoOrdinates={setOldCoOrdinates}
                    oldCoOrdinates = {oldCoOrdinates}
                    hotspotPins={hotspotPins}
                    setHotspotPins={setHotspotPins}
                    processedResolution={processedResolution}
                    originalResolution={originalResolution}
                    inverse={true}
                    autoplaySpeed={400}
                    location={imagePath}
                    format={process.env.REACT_APP_IMAGE_EXTENSION}
                    count={numberOfImages}
                    showhotspotControlBar
                    fullScreen
                    // handleFullScreenChange={toggleFullScreen}
                    // showFullScreenButton={showFullScreenButton}
                    showImages={showImages}
                    setShowImages={setShowImages}
                    // onNext={onNext}
                />:''
                }
                {/* this is the showing box when we click the share button  */}
                { showShareBox && <div className='share-link-box'>
                    <Sharebox show={setShowShareBox}/>
                </div>}
                <img onClick={()=>setShowShareBox(true)} src={shareButton} className="share-button" alt="" />
                <img src={companyLogo} className="company_logo"></img>
            </Box>
        </Box>
    </>
    )
};

const LogoWrapper = styled.header`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`; 

const Logo = styled.img`
  width: 200px;
`;

export default Hotspot;