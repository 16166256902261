import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

import './HotspotDetails.css'
import axios from 'axios';

const HotspotDetails = forwardRef((
    { oldCoOrdinates,setOldCoOrdinates,sessionId, hotspotPins, setClickedPointCoordinate, setIsPinClicked, setIsAddingHotspot, setHotspotPins, setIsCaptureClicked, currentImageIndex, leftDistance, topDistance, defaultSubject, defaultDescription, setImgSrc, imgSrc, actualImgSrc, pinData, clickedPinUid, isEditingHotspot, zoom, get_coordinates_for_zoom, hotspotImageNode, setHotspotImageNode, },) => {
    let background_color = 'ffbf00';
    const [path, setPath] = useState()
    useEffect(() => {
        return (() => {
            setImgSrc('');
            // setHotspotImageNode(null);
        })
    }, [])
    console.log(oldCoOrdinates,"oldCoOrdinates2")
    const getPathUrl = () => {
        const path = window.location.href.split("/")[3].split("?")[0]
        setPath(path)
    }
    useEffect(() => {
        // taking the url of the web 
        getPathUrl()
    }, [])
    const callApis = (data) => {
        axios.post(`${process.env.REACT_APP_INTEGRATION_API}integrationservice/callback/threesixty/update-three-sixty-hotspot`, data,
            {
                headers: {
                    'Access-Control-Allow-Origin' : '*',
                    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Content-Type': 'application/json',
                    'sessionid': sessionId,
                }
            }
        ).then((res) => {
            console.log(res,"response")
            if(res.status === 401 ){
               
            }else if(res.status !== 200){
                alert(res.statusText)
            }
        }).catch((err)=>{
            if(err.response?.status === 401){
                alert("the URL is expired")
                return
            }
                alert(err)
        })
    }
    const getData = (type, data) => {
        var tags = []
        if (type === 'remove') {
            tags = hotspotPins[currentImageIndex].filter(pins => {
                return pins.uid === clickedPinUid
            }).map((pins) => {
                console.log(pins, "heel")
                return {
                    "new_co_ordinates": {
                        "x": pins.co_ordinates.x,
                        "y": pins.co_ordinates.y
                    },
                    "hotspot_id":pins.uid ,
                    "description": pins.description,
                    "hotspot_image": pins.src.split(",")[1],
                    "title": pins.subject
                }
            })[0]
        }
        else if(type==="edit"){
            tags = hotspotPins[currentImageIndex].filter(pins => {
                return pins.uid === clickedPinUid
            }).map((pins) => {
                console.log(pins, "heel")
                return {
                    "co_ordinates": {
                        "x": pins.co_ordinates.x,
                        "y": pins.co_ordinates.y
                    },"hotspot_id":Date.now().toString(36) + Math.random().toString(36).substring(2) ,
                    "description": pins.description,
                    "hotspot_image": pins.src.split(",")[1],
                    "title": pins.subject
                }
            })[0]
        }
        else {
            tags = data
        }
        return {
            ...tags,
            "file_name": `${currentImageIndex}.png`,
            "hotspot_operation": type,
            "assessment_id": "eac442dd-791c-4ed2-a0eb-739f373f3478"
        }
    }

    const handleDelete = () => {
        setClickedPointCoordinate(null);
        setImgSrc('');
        setIsPinClicked(false);

        const data = getData("remove")
        callApis(data)
        setHotspotPins(prev => {
            prev[currentImageIndex] = prev[currentImageIndex].filter(pin => pinData['uid'] !== pin['uid']);
            return prev;
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(clickedPinUid, defaultSubject, "yes");
        if (e.target[0].value && e.target[0].value != '') {
            let { x, y } = get_coordinates_for_zoom(leftDistance, topDistance, 'decrease'); // when already zoomed in and you press save;
            console.log("SAVING THE DATA", e);
            if (clickedPinUid != '' && defaultSubject != '') {
                console.log("First hotspot saaving");
                console.log(e.target['childNodes']);
                setHotspotPins(prev => {
                    prev[currentImageIndex] = prev[currentImageIndex].map((pin) => {
                        if (pin['uid'] === clickedPinUid) {
                            pin['subject'] = `${e.target[0].value}`;
                            pin['description'] = `${e.target[1].value}`;
                            pin['src'] = `${e.target['childNodes'][2].src}`;
                        }
                        return pin;
                    })
                    return prev;
                })
                const data = getData("edit")
                callApis(data)
            }
            else {
                console.log(e.target['childNodes']);
                setHotspotPins(prev => {
                    prev[currentImageIndex] = [...prev[currentImageIndex], {
                        'uid': Date.now().toString(36) + Math.random().toString(36).substring(2),
                        'frameId': currentImageIndex,
                        'co_ordinates': { 'x': parseInt(`${x}`), 'y': parseInt(`${y}`) },
                        'subject': `${e.target[0].value}`,
                        'description': `${e.target[1].value}`,
                        'src': `${e.target['childNodes'][2].src}`
                    }]
                    return prev;
                });
                const tags = {
                    "co_ordinates": { 'x': parseInt(`${x}`), 'y': parseInt(`${y}`) },
                    "description": `${e.target[1].value}`,
                    "hotspot_image": `${e.target['childNodes'][2].src.split(",")[1]}`,
                    "title": `${e.target[0].value}`,
                    // "hotspot_id":pins.ui d ,
                }
                const data = getData("add", tags)
                callApis(data)

            }
            setClickedPointCoordinate(null);
            setImgSrc('');
            setIsPinClicked(false);
            setIsAddingHotspot(false);
        } else {
            e.target[0].focus();
        }
    }
    console.log("subject",)
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={(e) => handleSubmit(e)}
                style={{
                    width: '35vw', height: 'auto', maxHeight: '90vh', padding: '8px', borderRadius: '8px',
                    display: 'flex', flexDirection: 'column',
                    background: `#${isEditingHotspot ? background_color + 'cc' : background_color + 'aa'}`,
                    'pointer-events': `${isEditingHotspot ? 'auto' : 'none'}`
                }}
            // ref={onRefChange}
            >
                <TextField
                    onClick={(e) => e.target.focus()}
                    fullWidth
                    id="subject"
                    required
                    label="Subject"
                    variant="standard"
                    defaultValue={defaultSubject}
                />
                <TextField
                    onClick={(e) => e.target.focus()}
                    fullWidth
                    id="description"
                    label="Description"
                    variant="standard"
                    defaultValue={defaultDescription}
                    multiline
                    maxRows={2}
                />
                <img
                    id='hotspot-image'
                    src={(actualImgSrc === '' ? imgSrc : actualImgSrc) === '' ? 'data:,' : (actualImgSrc === '' ? imgSrc : actualImgSrc)}
                    style={{
                        width: '100%', position: 'relative', maxHeight: '40vh'
                    }}
                    alt=""
                // ref={ref}
                />
                {/* <span style={{position : 'absolute',}}><CloseIcon/></span> */}
                <div style={{ width: '100%', display: 'flex' }}>
                    <Button style={{ flex: 1 }} endIcon={<CloudUploadIcon />}>
                        <input type='file' id='upload-hotspot-image' name='upload-hotspot-image' accept="image/*" onChange={(e) => {
                            let blob = URL.createObjectURL(e.target.files[0]);
                            console.log(blob);
                            if (e.target && e.target.files && e.target.files.length > 0) {
                                convertBase64(e.target.files[0])
                                    .then(res => setImgSrc(res))
                                    .catch(err => console.log(err));
                            }
                        }} />
                    </Button>
                    {/* <Button id='capture-hotspot-image' name='capture-hotspot-image' onClick={() => setIsCaptureClicked(true)} endIcon={<CameraAltIcon />}>Take Image</Button> */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <Button
                        style={{ margin: '4px', color: '#F44336', borderColor: '#F44336' }} variant='outlined'
                        onClick={() => handleDelete()}
                        startIcon={<DeleteIcon />}
                        disabled={!isEditingHotspot}>Delete</Button>
                    <Button style={{ margin: '4px', background: '#388E3C', color: 'floralwhite' }} variant='contained' type='submit' endIcon={<SendIcon />} disabled={!isEditingHotspot}>Save</Button>
                </div>
            </Box>
        </>
    )
})

HotspotDetails.defaultProps = {
    setClickedPointCoordinate: () => { },
    setIsPinClicked: () => { },
    setHotspotPins: () => { },
    setIsAddingHotspot: () => { },
    setIsCaptureClicked: () => { },
    setImgSrc: () => { },
    currentImageIndex: 0,
    leftDistance: 0,
    topDistance: 0,
    defaultSubject: '',
    defaultDescription: '',
    imgSrc: '',
    actualImgSrc: '',
    clickedPinUid: '',
    pinData: {},
    isEditingHotspot: true,
    zoom: 1,
    get_coordinates_for_zoom: () => { },

}

export default HotspotDetails;