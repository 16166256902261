import React from "react";
import Viewer from "../../components/viewer";
import { useEffect, useState } from "react";
import styled from "styled-components";
import images from "../../assets/images";

const Home = () => {
  const [numberOfImages, setNumberOfImages] = useState(1);
  const [imagePath, setImagePath] = useState(
    "https://d1iddyy6okui5n.cloudfront.net/360view"
  );
  const [pins, setPins] = useState([]);
  const [originalResolution, setOriginalResolution] = useState(null);
  const vin_no = window.location.search ? window.location.search : "";
  const [fullScreen, setFullScreen] = useState(true);
  const [showFullScreenButton, setShowFullScreenButton] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showViewer, setShowViewer] = useState(true);

  useEffect(() => {
    // const api = `${
    //   process.env.REACT_APP_API_HOST
    // }CarscanUserDto/getcarspinbyvin?vin_number=${vin_no.substring(1)}`;
    console.log("Location " ,window.location.href)
    const core_file_id = window && window.location ? window.location.pathname.split('/')[2]: '';
    const vin_no = window && window.location ? window.location.pathname.split('/')[1]: '';
    console.log("Location id " ,core_file_id, vin_no)
    let api = `${process.env.REACT_APP_API_HOST}coreservice/public/threesixty`;
    if(vin_no){
      api+= `/${vin_no}`
    }
    if(core_file_id){
      api += `/${core_file_id}`;
    }
    //  = `${process.env.REACT_APP_API_HOST}coreservice/public/threesixty/${core_file_id}/${vin_no||''}`;
    fetch(api, {
      method: "GET",
      // headers: {
      //   "X-Api-Key": process.env.REACT_APP_API_KEY,
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
    })
      .then((res) => res.json())
      .then((response) => {
        if(+response.progress_percentage < 100 || response.error){
          setShowViewer(false);
          return
        }
        console.log(response)
        // setImagePath(response.urllinks.baseimagepath.slice(0, -1));
        // setNumberOfImages(response.urllinks.noofimages);
        // if (response.urllinks.finaljson.resolution) {
        //   setOriginalResolution(response.urllinks.finaljson.resolution);
        // }
        // setPins(response.urllinks.finaljson.finalJson);
        // setTimeout(
        //   function () {
        //     //Start the timer
        //     setShowImages({ render: true }); //After 1 second, set render to true
        //   }.bind(this),
        //   1500
        // );
        //console.log(response.base_path)
        setImagePath(response.base_path);
        setNumberOfImages(response.no_of_files);
        if (response.processed_image_resolution) {
          setOriginalResolution(response.processed_image_resolution);
        }
        setPins(response.tagged_files);
        setTimeout(
          function () {
            //Start the timer
            setShowImages({ render: true }); //After 1 second, set render to true
          }.bind(this),
          1500
        );
      })
      .catch((error) => {
        setShowViewer(false);
        console.log(error)
        // const response = {
        //   "id": "1cd67486-8dec-11eb-ba8a-0242ac12000320210326-043120",
        //   "no_of_files": "30",
        //   "progress_percentage": "100",
        //   "base_path": "https://carscan-core-general-files.s3.amazonaws.com/carscan-core-general-files/043ac7d7-ddbb-431e-a149-2ed6839b3ac4/25b5c638-d025-4532-9c49-4b831a5eef6e/1cd67486-8dec-11eb-ba8a-0242ac12000320210326-043120/public/",
        //   "gps": {
        //     "latitude": 0,
        //     "location_name": "string",
        //     "longitude": 0
        //   },
        //   "processed_image_resolution": {
        //     "height": "720",
        //     "width": "1440"
        //   },
        //   "original_image_resolution": {
        //     "height": "720",
        //     "width": "1440"
        //   },
        //   "tagged_files": [
        //     {
        //       "file_name": "string",
        //       "tags": [
        //         {
        //           "co_ordinates": {
        //             "x": "200",
        //             "y": "300"
        //           },
        //           "description": "some description",
        //           "tag_type": "PIN",
        //           "title": "Some title"
        //         }
        //       ]
        //     }
        //   ]
        // };
        // setImagePath(response.base_path.slice(0, -1));
        // setNumberOfImages(response.no_of_files);
        // if (response.processed_image_resolution) {
        //   setOriginalResolution(response.processed_image_resolution);
        // }
        // setPins(response.processed_image_resolution);
        // setTimeout(
        //   function () {
        //     //Start the timer
        //     setShowImages({ render: true }); //After 1 second, set render to true
        //   }.bind(this),
        //   1500
        // );
      });
   

  }, [vin_no]);

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <Wrapper>
      {
        !showViewer && (
          <div style={{marginTop: "10em"}}>
          <h1 style={{color: "red"}}>360° Viewer Not Available!</h1>
          <p>Please try after sometime!</p>
          </div>
        )
      }
      {!fullScreen && (
        <LogoWrapper>
          <Logo src={images.LOGO} alt="logo" />
        </LogoWrapper>
      )}

      <SpinViewWrapper
        className={`image-viewer ${fullScreen ? "Viewer-full-screen" : null}`}
      >
        <Viewer
          pinss={pins}
          originalResolution={originalResolution}
          inverse={true}
          autoplaySpeed={400}
          location={imagePath}
          format={process.env.REACT_APP_IMAGE_EXTENSION}
          count={numberOfImages}
          showControlBar
          fullScreen
          handleFullScreenChange={toggleFullScreen}
          showFullScreenButton={showFullScreenButton}
          showImages={showImages}
        />
      </SpinViewWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  .Viewer-full-screen {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .icons {
    position: absolute;
    top: 121px;
    background-color: rgba(232, 236, 241, 0.7);
    padding: 6px;
  }
  .icons-full-screen {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(232, 236, 241, 0.7);
    padding: 6px;
  }
  .hide {
    display: none;
  }
  .user-guide {
    height: 375px;
    width: 500px;
    position: absolute;
    top: 121px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 480px) {
      width: 100%;
      font-size: 10px;
    }
    .drag-guide {
      display: flex;
      background: #fbfbfb;
      padding: 10px;
      border-radius: 30px;
      align-items: center;
      .drag-image {
        height: auto;
        width: 75px;
      }
    }
  }
`;
const Logo = styled.img`
  width: 200px;
`;

const LogoWrapper = styled.header`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`;
const ICON = styled.img`
  height: 25px;
  width: 25px;
  margin: 0px 5px;
`;
const SpinViewWrapper = styled.div``;

export default Home;
