import React, { useEffect, useState } from 'react'
import './index.css'
import closeIcon from '../../../../../src/assets/images/share/close-button.svg'
import twittericon from '../../../../../src/assets/images/share/twitter.svg'
import facebook from '../../../../../src/assets/images/share/facebook.svg'
import whatsapp from '../../../../../src/assets/images/share/whatsapp.svg'
import mail from '../../../../../src/assets/images/share/mail.svg'
const Index = ({ show }) => {
    const [link, setLink] = useState("")
    const [access,setAccess] = useState("viewer")
    const [copyBtn , setCopyBtn ] = useState("copy")

    const getPathUrl = () => {
        const path = window.location.href.split("?")[0]
        setLink(path)
    }

    useEffect(() => {
        // taking the url of the web 
       getPathUrl()
    }, [])
    
    // when user click the facebook whatsapp twitter and mail this fun will call 
    const shareButtonClicked = (item) => {
        // checking its whether mobile or desktop 
        const userDevice = navigator.userAgent.toLowerCase()
        var isMobile = userDevice.indexOf("mobile") > -1;
        switch (item) {
            case "whatsapp":
                if (isMobile) {
                    window.location.href = "whatsapp://send?text=your_message&phone=123456789";
                } else {
                    window.open(`https://web.whatsapp.com/send?text=${link}`, "Share with Whatsapp Web", 'width=800,height=600');
                }
                break;
            case "facebook":
                    window.open("https://www.facebook.com/sharer/sharer.php?u=360.carscan.ai")
                break;
            case "twitter":
                window.open(`https://twitter.com/intent/tweet?text=${link}`) 
                break;
            default:
                window.open(`mailto:?subject=${"carscan 360"}&body=${link}`)
                break;
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(link).then((res)=>{
            setCopyBtn("Copied !")
            setTimeout(()=>{
                setCopyBtn("Copy")
            },3000)
        })
    }

    
    return (
        <div className='box'>
            <div className="heading-closeicon">
                <p className="share-heading">
                    Share link via
                </p>
                <img onClick={() => show(false)} src={closeIcon} alt="" />
            </div>
            <div className="sm-icons">
                <div onClick={() => shareButtonClicked("twitter")} className="twitter-icon">
                    <img src={twittericon} alt="" />
                    <p className="sm-name">Twitter</p>
                </div>
                <div onClick={() => shareButtonClicked("facebook")} className="facebook-icon">
                    <img src={facebook} alt="" />
                    <p className="sm-name">Facebook</p>
                </div>
                <div onClick={() => shareButtonClicked("whatsapp")} className="whatsapp-icon">
                    <img src={whatsapp} alt="" />
                    <p className="sm-name">Whatsapp</p>
                </div>
                <div onClick={() => shareButtonClicked("email")} className="mail-icon">
                    <img src={mail} alt="" />
                    <p className="sm-name">Email</p>
                </div>
            </div>
            <div className="access-section">
                <p className="copy-heading">
                    Or copy page link
                </p>
               
            </div>
            <div className="copy-text">
                <input type="text" readOnly value={link} />
                <button onClick={handleCopy}>{copyBtn}</button>
            </div>
        </div>
    )
}

export default Index 