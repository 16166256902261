import React from 'react';
import styles from './styles.module.css';

const Pins = ({
	originalResolution,
	pins,
	viewerWidth,
	viewerHeight,
	currentFrameId,
	pinWidth,
	pinHeight,
	renderPin,
	onPinDoubleClick,
	onPinClick,
	isFullScreen
}) => {
	const getPosition = (pin, index) => {
		// pin.x = 400;
		// pin.y = 400;
		pin.displayMore = false;
		 let top, left, width, height;
		// //console.log(viewerWidth);
		if(originalResolution) {
			width = originalResolution.width;
			height = originalResolution.height;
		} else {
			width= 1280;
			height = 720;
		}
		//console.log("Viewer Width:",viewerWidth, "Viewer Height:", viewerHeight, "x:",((pin.x1 + pin.x2) / 2),"y",((pin.y1 + pin.y2) / 2));
		if(!isFullScreen) {
			left = ((viewerWidth * (((+pin.x + 10) / 2)/width)) - pinWidth)+15;
			top = ((viewerHeight * (((+pin.y + 10) / 2) / height)) - pinHeight)+17;
		} else {
			left = viewerWidth*((((+pin.x + 10)/2)-4)/width);
			top = viewerHeight*((((+pin.y + 10)/2)-18)/height);
		}
		
		if (left < 0) left = '';
		if (top < 0) top = '';
		
		return { left, top };
	};

	return (
		<div className="Viewer-dots-wrapper">
			{pins?.length > 0 &&
				pins
					.filter((pin) => pin.file_name.slice(0, -4) == currentFrameId)[0]?.tags
					.map((pinn, index) =>
						renderPin ? (
							<div
								key={index}
								className={`${styles['Viewer-pin']}`}
								style={getPosition(pinn.co_ordinates)}
								onDoubleClick={(e) => {
									e.stopPropagation();
									onPinDoubleClick(pinn);
								}}
								onClick={(e) => {
									e.stopPropagation();
									onPinClick(pinn);
								}}
							>
								{renderPin(pinn)}
							</div>
						) : (
							<div
								key={index}
								className={`${styles['Viewer-dot']}`}
								style={getPosition(pinn.co_ordinates)}
								onDoubleClick={(e) => {
									e.stopPropagation();
									onPinDoubleClick(pinn);
								}}
								onMouseOver={(e) => {
									e.stopPropagation();
									onPinClick(pinn);
									pinn.displayMore = true;
								}}
								onMouseOut={(e) => {
									e.stopPropagation();
									onPinClick(pinn);
									pinn.displayMore = false;
								}}
							>
								{pinn.displayMore ?<div className={`${styles['info-wrapper']}`}>
									<p className={`${styles['info-wrapper-title']}`}>{pinn.title}</p>
									<p className={`${styles['info-wrapper-description']}`}>{pinn.description}</p>
								</div>:''}
							</div>
						
						)
					)}
		</div>
	);
};

Pins.defaultProps = {
	pins: [],
	pinWidth: 20,
	pinHeight: 20,
	onDoubleClick: () => {},
	onPinClick: () => {}
};

export default Pins;
