// import './App.css';
import { BrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import Hotspot from "./pages/Hotspot";

function App() {
  return (
    <BrowserRouter >
    <div className="App">
      {/* <Home /> */}
      <Hotspot />
    </div>
    </BrowserRouter>
  );
}

export default App;
